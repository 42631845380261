:root {
  --primary-color: #f97203;
  --secondary-color: #150578;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
body {
  margin: 0;
  font-family: "DM Sans", sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  width: 100%;
  max-width: 1220px;
  margin: 0 auto;
}
@media only screen and (max-width: 859px) {
  .container {
    /* padding: 0 15px; */
  }
}

/* SIZE */
.x100 {
  width: 100%;
}
.y100 {
  height: 100%;
}
.vh100 {
  height: 100vh;
}

/* FLEX */
.flex {
  display: flex;
}
.flexSpaceCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexSpaceAround {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flexNullCenter {
  display: flex;
  align-items: center;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexSpaceNull {
  display: flex;
  justify-content: space-between;
}

.flexEnd {
  display: flex;
  align-items: flex-end;
}

.flexGrow {
  flex-grow: 1;
}

/* GAP */
.gap5 {
  gap: 5px;
}
.gap10 {
  gap: 10px;
}
.gap15 {
  gap: 15px;
}
.gap20 {
  gap: 20px;
}

/* ALIGN */
.textCenter {
  text-align: center;
}

/* FONT SIZE */
.font10 {
  font-size: 10px;
}
.font11 {
  font-size: 11px;
}
.font12 {
  font-size: 12px;
}
.font13 {
  font-size: 13px;
}
.font20 {
  font-size: 20px;
}
.font30 {
  font-size: 30px;
}

/* WEIGHT */
.semiBold {
  font-weight: 600;
}
.bold {
  font-weight: bold;
}

/* RADIUS */
.r5 {
  border-radius: 5px;
}
.r10 {
  border-radius: 10px;
}
.r15 {
  border-radius: 15px;
}

/* CURSOR */
.pointer {
  cursor: pointer;
}

/* COLOR */
.black {
  color: black;
}
.white {
  color: white;
}
.main {
  color: var(--primary-color);
}
.secondary {
  color: var(--secondary-color);
}
.grey {
  color: gray;
}
/* BACKGROUND */
.secondaryBg {
  background-color: var(--secondary-color);
}

/* HELPERS */
.animate {
  transition: 0.3s ease;
}
.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}

/* PADDING */
.p5 {
  padding: 5px;
}
.p10 {
  padding: 10px;
}
.p15 {
  padding: 15px;
}
.p20 {
  padding: 20px;
}
/* MARGIN */
.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb15 {
  margin-bottom: 15px;
}
.mb20 {
  margin-bottom: 20px;
}
